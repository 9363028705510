*::selection {
  background: $pink;
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 2rem 0;

  @include respond-to('small') {
    font-size: 15px;
  }
}

a {
  text-decoration: none;
  color: $white;

  @include respond-to('small') {
    font-size: 15px;
    color: $white;
  }
}

ul {
  padding: 0;
  list-style: none;

  li {
    display: flex !important;
    align-items: center;
    padding: 1rem 0;
    font-weight: 600;

    &.with-icon:before {
      content: '';
      display: inline-block;
      height: 2rem;
      background-image: url('/assets/images/icons/icon_checked.svg');
      background-size: contain;
      background-repeat: no-repeat;
      padding-left: 4rem;
      vertical-align: middle;
    }
  }
}

label {
  display: block;

  @include respond-to('small') {
    font-size: 2rem;
    padding: 5px;
  }
}

input, textarea{
  width:100%;
  border:2px solid #aaa;
  border-radius:4px;
  margin:8px 0;
  outline:none;
  padding:8px;
  box-sizing:border-box;
  transition:.3s;
}

input:focus{
  border-color:dodgerBlue;
  box-shadow:0 0 8px 0 dodgerBlue;
}

textarea {
  resize: vertical;
}

.social-wrapper {
  position: absolute;
  bottom: 175px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
  padding-left: 1rem;

  @include respond-to('large') {
    position: relative;
    bottom: auto;
  }


  &:after {
    content: '';
    position: absolute;
    bottom: -8rem;
    left: calc(2rem - 1px);
    height: 7rem;
    width: 2px;
    background: rgba(255, 255, 255, 0.6);
  }
}

.contact-link {
  display: inline-block;
  position: relative;
  color: $color-attention;
  font-size: 2rem;

  a {
    color: $color-attention;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    height: 2px;
    width: 6rem;
    background: $color-attention;

    @include respond-to('large') {
      left: -7rem;
      right: auto;
    }
  }
}