.open-menu i:nth-child(3) {
	top: 25px;
	-webkit-transform: rotateZ(-45deg);
	        transform: rotateZ(-45deg);
}

nav ul {
	padding: 0 10px;
	display: flex;
}
nav li {
	flex: 1;
}
nav li a {
	font-size: 2em;
	display: block;
	padding: 30px;
	text-align: center;
	transition: background .3s;
}
nav li a {
	background: #ff4b4b;
	margin-left: 20px;
}

#fp-nav ul li a span, 
.fp-slidesNav ul li a span {
	background: white;
	width: 8px;
	height: 8px;
	margin: -4px 0 0 -4px;
}
#fp-nav ul li a.active span, 
.fp-slidesNav ul li a.active span, 
#fp-nav ul li:hover a.active span, 
.fp-slidesNav ul li:hover a.active span {
	width: 16px;
	height: 16px;
	margin: -8px 0 0 -8px;
	background: transparent;
	box-sizing: border-box;
	border: 2px solid #212121;
}
.fp-tableCell {
	display: inline-flex;
}
 
@media screen and (max-width: 767px) {
	nav ul {
		flex-direction: column;
	}
 
	nav li {
		margin-top: 1px;
	}
  
  nav li a {
    font-size: 1.5em;
  }
  
  .scroll-icon {
    display: none;
  }
}
 
@media screen and (max-width: 400px) {
  html {
    font-size: 50%;
  }
  
  .open-menu ~ nav {
		padding: 20px 0;
	}
 
	nav li a {
		padding: 3px;
	}
}