header {
  position: fixed;
  bottom: 0rem;
  width: 100%;
  z-index: 3;
  transition: background-color 0.4s ease-out;

  @include respond-to('small') {
    bottom: unset !important;
  }

  @include respond-to('large') {
    bottom: auto;
  }

  &.scrolled {
    background: $color-primary;

    @include respond-to('large') {
      transform: translateY(-1rem);
      background: transparent;
    }
  }
}

@include respond-to('small') {
  .nav-links {
    position: fixed;
  }
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  margin: 1rem;

  @include respond-to('medium') {
    padding: 0 4rem;
  }

  a.nav-link {
    display: inline-block;
    position: relative;
    height: 50px;
    line-height: 50px;
    margin-left: 1.75rem;
    color: $white;
    font-size: 1.2rem;
    font-weight: 600;
    transition: color 0.25s linear;
    animation-delay: 0.5s;

    @include respond-to('large') {
      margin-left: 2rem;
      & header.scroll {
        color: $black !important;
      }

      &:last-of-type {
        margin-right: 4rem;
      }
    }
    @include respond-to('small') {
      font-size: 15px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: $color-secondary;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform .35s ease-out;
    }

    &:hover:after {
      transform: scale(1);
    }
  }
}
.logo {
  margin-top: -18px;

  @include respond-to('small') {
    margin-top: -10px;
  }
}

@for $i from 1 through 10 {
  nav a.fade-in:nth-child(#{$i}n) {
    animation-duration: #{$i * .5}s;
  }
}

.strip {
  height: 11%;
  width: 100%;
  position: inherit;

  @include respond-to('small') {
    display: none;
  }
}

@include respond-to('small') {
  .strip-on-mobile {
    height: 11%;
    width: 100%;
    position: inherit;
  }

  .on-top-mobile {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-row-gap: 2rem;
    justify-items: stretch;
    padding: 2rem 1rem;
  }
}

.on-top {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 30rem;
  grid-row-gap: 2rem;
  justify-items: stretch;
  padding: 2rem 4rem;

  @include respond-to('small') {
    display: none;
  }
}

@include respond-to('large') {
  .strip-on-mobile {
    display: none;
  }
}

.email-icon, .phone-icon {
  width: 15%;
  height: auto;
}