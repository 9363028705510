section {
  position: relative;
  padding: 2rem 2rem calc(45px + 2rem);
  color: #000;

  @include respond-to('large') {
    height: 100vh;
  }

  .section-col {
    z-index: 1;

    @include respond-to('small') {
      margin-bottom: -7rem;
    }

    @include respond-to('large') {
      margin-bottom: 0;
      margin-left: auto;
      margin-right: auto;

      &.col-full-width {
        width: 100%;
      }

      &.col-right {
        padding-left: 4rem;
        margin-top: 4rem;
      }

      &.col-align-end {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        // IE Bug fix
        > h3 {
          width: 100%
        }
      }
    }
  }

  &.section--approach,
  &.section--contact,
  &.section--register,
  &.section--candidates {
    @include respond-to('large') {
      background-repeat: no-repeat;
      background-position: left;
      height: 100%;
    }
  }

  @include respond-to('medium') {
    padding: calc(75px + 4rem) 12rem;
  }

  display: flex;

  @include respond-to('large') {
    &.section--align-center {
      align-items: center;
    }
  }
}

main {
  background: linear-gradient(155deg, rgba(255, 255, 255, 0.59) 0%, rgba(24, 73, 106, 0.5) 50%, rgba(19, 139, 137, 1));
}

.section--start {
    background-image: linear-gradient(155deg, rgba(0, 47, 98, 0.5) 0%, rgba(24, 73, 106, 0.5) 50%, rgba(19, 139, 137, 0.5)),
    url('/assets/images/nurse.jpg');
    background-repeat: no-repeat;
    background-size: 100%;

    @include respond-to('large') {
      background-attachment: fixed;
    }
    opacity: 1;
}

.container {
  background-image: url('/assets/images/programmer.webp');
  background-repeat: no-repeat;
  background-size: 100%;
}

.overlay-apps {
  width: 100%;
}

.svg-wave {
  position: absolute;
  width: 100%;
  top: 38rem;
  height: auto;
}

* {
  box-sizing: border-box;
}
.column {
  float: left;
  padding: 10px;
  margin-top: 8rem;

  @include respond-to('large') {
    width: 45%;
  }

  @include respond-to('small') {
    width: 100%;
    height: 40%;
  }
}

.cards-column {
  text-align: center;
  margin-top: 15rem;
  margin-left: 22%;
  margin-right: 22%;

  @include respond-to('small') {
    margin-top: 55rem;
    margin-left: 0%;
  }

  @include respond-to('small') {
    width: 100%;
    height: 40%;
  }
}

.work-with-us {
  text-align: center;
  margin-top: 5rem;
}

.column-mobile {
    height: 100px;
}

.column-wrapper {
  float: left;
  width: 50%;
  padding: 10px;
  height: 100%;
}

.left-column:after {
  content: "";
  display: table;
  clear: both;
}
.row {
  height: 67%;
  width: 100%;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}

.card {
  position: relative;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  cursor: pointer;
  text-align: left;
  margin-bottom: 10px;

  @include respond-to('large') {
    width: 100%;
    display: inline-block;
  }

  @include respond-to('small') {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
}

.svg-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  width: 120%;
}

.blob {
  transform: translate(23%,3%) scale(0.65);
  fill: rgb(15, 59, 64);
  transition: 0.4s;
}

.card.active .blob {
  fill: rgb(86, 114, 140);
  transform: translate(23%, 3%) scale(3.75);

  @include respond-to('small') {
    transform: translate(23%, 3%) scale(4.75);
  }
}

.card.active .bg-overlay {
  opacity: 0;
}

.circular-border {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgb(12, 51, 57);
  position: relative;
}

.description {
  opacity: 0;
  transition: 0.4s;
}
.card.active p {
  opacity: 1;
  color: white;
}

.top-section {
  font-size: 22px;
  color: black;
  margin-top: 3rem;
  opacity: 1;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.section-title {
  margin: 0;
  color: #4285F4;
  font-size: 3.998em;
  font-weight: 900;
  line-height: 1.2;
}

h4.section-sub-title {
  font-family: Raleway,sans-serif;
  color: #A7A7A7;
  font-size: 2.414em;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.section-img {
  width: 100%;

  @include respond-to('large') {
    height: 90%;
  }
}

.text-overlay {
  position: relative;
  text-align: center;
  color: white;
}
.overlayed {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #1d4d6f !important;
  font-size: x-large;
  font-weight: bold;
}
.form-wrapper {
  padding: calc(75px + 4rem) 12rem;

  @include respond-to('small') {
    padding: 1rem;
  }
}

#radio label {
	float: right;
  display: inline
}
#radio {
    display: inline;
}
#radio input {
	width: auto;
	margin-left: 3em;
}
.radio-buttons {
  display: flex;
  padding-left: 10px;
}

.card1, .card2, .card3 {
  // margin: 30px;
  // padding: 1em;
  // width: 10em;
  height: 75%;
  padding-bottom: 1rem;

  @include respond-to('small') {
    margin-bottom: 3rem;
  }
  // background-color: white;
}

.section-title {
    text-align: center;
    margin-bottom: 4rem;
}

img {
  width: 100%;
  height: auto;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 4rem;
}

.stack-card {
  max-width: 500px;
  width: 100%;
  height: 14em;
  position: relative;
  padding: 3rem 2rem;
  color: white;
  justify-self: center;
  transition: all 0.3s cubic-bezier(0.25, 0.45, 0.45, 0.95);
  transform: scale(1.02);

  .card-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    .bottom h2 {
      font-size: 2.6rem;
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    font-size: 1.8rem;
    .name {
      font-size: 1.8rem;
    }
    .date {
      font-weight: bold;
      opacity: 0.8;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .bookmark {
      margin-left: 5rem;
      border: 2px solid white;
      padding: 0.7rem;
      border-radius: 50%;
      .bookmark-icn {
        fill: white;
      }
      &:hover {
        cursor: pointer;
        background-color: rgba($color: #fff, $alpha: 0.5);
      }
    }
  }

  .card-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 20px;
    overflow: hidden;
    z-index: -1;
    .bg-img {
      height: 100%;
      object-fit: cover;
      vertical-align: middle;
    }
  }

  .shadow {
    position: absolute;
    width: 90%;
    height: 100%;
    overflow: hidden;
    display: flex;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -15px;
    z-index: -2;
    border-radius: 20px;
    filter: blur(15px);
    .shadow-img {
      margin-top: 3rem;
      object-fit: cover;
    }
  }
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  font-size: 30px;
  color: #50cdff;
  font-family: sans-serif;
  text-shadow: 3px 2px 1px #000000;
}

.text-shadow {
  font-size: 30px !important;
  color: #50cdff !important;
  font-family: sans-serif;
  text-shadow: 2px 1px 1px #000024;
}

.section--candidates {
  background-image: linear-gradient(155deg,rgba(0,47,98,.5),rgba(24,73,106,.5) 50%,rgba(19,139,137,.5)),url("/assets/images/cartoon_work.jpeg");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 1;

  @include respond-to('small') {
    background-image: linear-gradient(155deg,rgba(0,47,98,.5),rgba(24,73,106,.5) 50%,rgba(19,139,137,.5)),url("/assets/images/care_crop.jpg");
  }
}

#register-form {
  background: linear-gradient(155deg,hsla(0,0%,100%,.59),rgba(24,73,106,.5) 50%,#138b89);
}