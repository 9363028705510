#icons-grid {
    display: block;
    padding: 4rem 2rem calc(50px + 4rem);
    color: $white;
  
    @include respond-to('large') {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-column-gap: 8rem;
      grid-row-gap: 2rem;
      justify-items: stretch;
      position: sticky;
      margin-top: 1rem;
    }

    & i.fa {
        font-size: 8em;
    }
}

.my-blog {
  display: grid;
  grid-template-columns: repeat(3, 32%);
  justify-content: space-between;
}

#blog-section {
  position: relative;
  padding: 2rem 2rem calc(75px + 2rem);
  color: #000;
}

.img-blog {
  width: 100%;

  @include respond-to('large') {
    width: 100%;
    height: 12rem;
  }
}
.slide-info {
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 34px;
  text-align: center;
  color: #1f2532;
}
.slide {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  box-shadow:0 4px 10px 4px rgba(#13232f,.3);
  background-color: #e4dfdf;
}

#grid {
  display: block;
  color: $white;

  @include respond-to('large') {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-column-gap: 8rem;
    grid-row-gap: 2rem;
    justify-items: stretch;
    position: sticky;
  }
  @include respond-to('small') {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-column-gap: 8rem;
    grid-row-gap: 2rem;
    justify-items: stretch;
    position: sticky;
  }
}

.grid-box {
  color: $white;

  @include respond-to('large') {
    display: grid;
    grid-template-columns: repeat(3, 32%);
    justify-content: space-between;
  }

   @include respond-to('small') {
     display: grid;
   }
}

.logo-grid-box {
  @include respond-to('large') {
    width: 65rem;
    max-width: 1000px;
  }

  @include respond-to('small') {
    width: 100%;
  }

  margin: auto;
}

.logogrid {
   display: grid;

  @include respond-to('large') {
    grid-template-columns: repeat(auto-fill, minmax($logo-max-width * 1.25, 1fr));

    grid-gap: 4rem;
  }

  @include respond-to('small') {
    grid-template-columns: repeat(auto-fill,minmax(155px,1fr));
    grid-auto-rows: $logo-max-height * 1.25;
    grid-gap: 2rem;
  }

  &_item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_img {
    object-fit: cover;
    max-width: $logo-max-width;
    max-height: $logo-max-height;
    filter: grayscale(100%) contrast(25%);
    transition: filter .3s ease;

    &:hover {
      filter: grayscale(0) contrast(100%);
    }
  }
}