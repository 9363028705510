@mixin respond-to($breakpoint) {
  @if $breakpoint=="x-small" {
    @media (max-width: 375px) {
      @content;
    }
  }
  @else if $breakpoint=="small" {
    @media (max-width: 480px) {
      @content;
    }
  } @else if $breakpoint=="medium" {
    @media (min-width: 767px) {
      @content;
    }
  } @else if $breakpoint=="large" {
    @media (min-width: 990px) {
      @content;
    }
  } @else if $breakpoint=="x-large" {
    @media (min-width: 1369px) {
      @content;
    }
  } @else {
    @media ($breakpoint) {
      @content;
    }
  }
}

@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}
