.btn {
  display: inline-block;
  outline: none;
  border: none;
  padding: 1rem 2rem;
  border-radius: 4px;
  font-weight: 600;
  background: transparent;

  @include respond-to('small') {
    margin-right: 20rem;
    margin-top: 3rem;
    font-size: 3rem;
  }

  @include respond-to('large') {
    padding: 1rem 2rem;
  }
}

.btn-default {
  background: $color-secondary;
  border: 2px solid transparent;
  color: $white;
  box-shadow: $box-shadow-primary;
  max-width: 250px;
  transition: all 0.35s linear;

  &:hover {
    background: darken($color-secondary, 5%);
    transform: translateY(-2px);
  }
}
.btn-explore {
    border: 2px solid black;
    color: #000;
    box-shadow: 0 1px 3px rgba(56, 58, 57, 0.5);
    transition: all .35s linear;
}

@include respond-to('small') {
  .large-viewport {
    display: none;
  }
}

.button {
  position: relative;
  display: inline-block;
  text-decoration: none;
  vertical-align: middle;
  padding: 18px 30px;
  width: 30%;
  font-size: 16px;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid transparent;
  margin-top: 4rem;

  @include respond-to('small') {
    width: 28%;
    font-size: 15px;
    padding: 10px 0px;
    margin-top: 2rem;
  }

  &:focus {
    outline: none;
  }
  &:active {
    transform:scale(0.95);
  }
  &.rounded {
    border-radius: 28px;
    background-color: aqua;
  }
  .border {
    &::before,
    &::after {
      position: absolute;
      content: "";
    }
    &::before {
      width: calc(100% + 2px);
      height: 2px;
    }
    &::after {
      width: 2px;
      height: calc(100% + 2px);
    }
  }

  &.one {
    color: green;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      border: 2px solid red;
      transform:translateX(-50%) translateY(-50%);
    }
    &.rounded::after {
      border-radius: 28px;
    }
}
}