.flex-column {
  display: flex;
  flex-direction: column;
  margin-bottom: 8rem
}

.flex-row {
  display: flex;
  align-items: center;

  @include respond-to('large') {
    &.flex-end {
      justify-content: flex-end;

      p {
        text-align: right;
      }
    }
  }
}

.m-right-1x {
  margin-right: 1rem;
}

.m-top-1x {
  margin-top: 1rem;
}

.m-top-2x {
  margin-top: 2rem;
}

.m-top-4x {
  margin-top: 1rem;

  @include respond-to('small') {
    margin-top: 4rem;
  }
}

.m-left-10x {
  margin-left: 10rem;
}

.m-top-10x {
  margin-top: 10rem;

  @include respond-to('small') {
    margin-top: 4rem;
  }
}

.m-top-8x {
  font-size: xx-large;
  text-decoration: underline #329194;
  
  @include respond-to('small') {
    margin-top: 8rem;
    font-size: xx-large;
  }
}

.m-bottom-1x {
  margin-bottom: 1rem;
}

.m-bottom-2x {
  margin-bottom: 2rem;
}

.centered {
  @include respond-to('small') {
    margin-left: auto;
    margin-right: auto;
  }
}

.boxes-center {
  margin-left: auto;
  margin-right: auto;
}

.max-w-75 {
  max-width: none;

  @include respond-to('large') {
    max-width: 100%;
  }
}

.h-x0 {
  height: 0;
}

.h-100x {
  height: 100%;
}

.h-47 {
  @include respond-to('small') {
    height: 47rem;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.show-on-lg {
  display: none;

  @include respond-to('large') {
    display: block;
  }
}

.svg-icon {
  display: inline;
  vertical-align: middle;

  &.svg-icon--sm {
    width: 2rem;
    height: 2rem;
  }

  &.svg-icon--md {
    width: 3rem;
    height: 3rem;
  }
}

.txt-a-right {
  text-align: right;
}

.txt-a-centre {
  text-align: center !important;
}

.txt-a-right--lg {
  @include respond-to('large') {
    text-align: right;
  }
}

.txt-c-trans-6 {
  color: rgba(255, 255, 255, 0.6);
}

.txt-c-trans-8 {
  color: hsla(0, 100%, 100%, 0.8);
}

.txt-sm {
  font-size: 0.8rem;
}

.txt-lg {
  font-size: 1.5rem;

  @include respond-to('small') {
    font-size: 2rem;
  }
}

.txt-xl {
  font-size: 13px;

  @include respond-to('small') {
    font-size: 2.4rem;
  }
}

.txt-4 {
  font-size: 4rem;

  @include respond-to('small') {
    font-size: 2rem;
  }
}

.txt-w-600 {
  font-weight: 600;
}

.txt-xl {
  font-size: 2rem;
  color: white !important;

  @include respond-to('small') {
    font-size: 15px;
  }
}

.w-100 {
  @include respond-to('large') {
    width: 100%;
  }

  @include respond-to('small') {
    width: 100%;
  }
}

.w-50 {
  width: 50%;

  @include respond-to('small') {
    width: 100%;
  }
}
.w-03 {
  width: 3%;

  @include respond-to('small') {
    width: 10%;
  }
}

.w-75 {
  width: 75%;
}

// .w-100 {
//   width: 100%;
// }

.m-top-minus-10 {
  margin-top: -10rem;

  @include respond-to('small') {
    margin-top: 4rem;
  }
}

.no-style {
  background: none !important;
}
.no-padding {
  padding: 0;
}
.no-margin {
  margin: 0 !important;
}
.f-size-14x {
  font-size: 14px;
}
.f-size-10x {
  font-size: 10px;
}
.f-weight {
  font-weight: normal
}
.f-x-large {
  font-size: x-large;
}

.c-white {
  color: $white;
}

.c-black {
  color: $black;
}

.l-height-15 {
  line-height: 15px;
}
.opacity-05 {
  opacity: 0.5;
}

.top-bottom-c {
  margin-top: auto;
  margin-bottom: auto;
}

.left-32x {
  left: 32%;

  @include respond-to('small') {
    left: 24%;
  }
}
.left-16x {
  left: 16%;

  @include respond-to('small') {
    left: 9%;
  }
}
