.glide {
  position: relative;
  height: 300px;
  width: calc(100% + 4rem);
  transform: translateX(-2rem);
  box-sizing: border-box;

  @include respond-to('x-large') {
    height: 500px;
    width: calc(100% + 8rem);
    transform: translateX(-4rem);
  }
}

.glide-controls {
  position: absolute;
  top: -8rem;
  right: 2rem;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  height: 100%;
  overflow: hidden;
}

.glide__slides {
  position: relative;
  height: 100%;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}

.glide__slides--dragging {
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}

.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}
