//? set your brand colors

$white: #fff;

$light-grey: #fafafa;
$grey: #737687;
$dark-grey: #434656;

$black-ish: #444;
$black: #09090e;

$blue: #2c638f;
$pink: #fff0f5;
$yellow: #fffacd;

$color-primary: #4285F4;
$color-secondary: #00E3BD;
$color-attention: #e30000;
$color-typography: #424656;

$box-shadow-primary: 0 1px 3px rgba(36, 180, 126, .4);

$logo-max-width: 120px;
$logo-max-height: 60px;