* {box-sizing: border-box; margin: 0; padding: 0;}

.modal {
  align-items: center;
  background-color: rgba(52, 73, 94, .6);
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  position: fixed;
  opacity: 0;
  overflow: hidden;
  top: 0;
  transform: translateY(-100%);
  transition: all .5s;
  width: 100%;
}
.modal:target{
  opacity: 1;
  transform: translateY(0);
}

.modal:target .modal-content{
  height: 40%;
  margin-top: none;
  opacity: 1;
  border-radius: 10px;
  overflow: scroll;
}

.modal:not(:target) .modal-content {
  height: 0px;
  margin-top: -200%;
  opacity: 0;
}

/*  */

.modal-content {
  background-color: #fff;
  display: block;
  height: 0px;
  margin: 10px;
  opacity: 0;
  position: relative;
  transition: all .6s; 
  width: 500px;
}

.modal-header {
  align-items: center;
  color: #444;
  display: flex;
  height: 25%;
  justify-content: space-between;
  padding: 0px 25px;
}

.header-btn-close {
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  text-decoration: none;
}

.modal-header-title {
  font-size: 20px;
  letter-spacing: 1px;
}


.header-top { 
    background-color: #56728c;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.modal-body {
  height: 75%;
  width: 100%;
}

.padding-15 {
    padding: 0 15px;
}
